import { css } from "styled-components";

import { EpiImageOverlay } from "types/EpiImage";

const getGradient = (overlay: EpiImageOverlay) => {
    switch (overlay) {
        case "UpRight":
            return `var(--gradient-upright)`;

        case "UpLeft":
            return `var(--gradient-upleft)`;
        default:
            return "";
    }
};

const base = css`
    content: "";
    display: block;
    inset: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
`;

export const darken = () => css`
    ${base};
    background: var(--image-darken);
    z-index: 2;
`;

export const overlay = (overlay: EpiImageOverlay) => css`
    ${base};
    background: ${overlay && getGradient(overlay)};
    mix-blend-mode: color;
`;
