import { styled, css } from "styled-components";

import { styleBodyS } from "style/components/Typography";
import { MQ } from "style/mediaQueries";
import { overlay, darken } from "style/overlay";
import { EpiImageOverlay } from "types/EpiImage";

export const Figure = styled.figure.attrs<{
    $overlay?: EpiImageOverlay;
    $darken?: boolean;
    $cover?: boolean;
    $maxWidth?: number;
    $hasCaption?: boolean;
    $centerPosition?: [number, number];
}>(() => ({}))`
    height: auto;
    margin: 0;
    ${(props) =>
        props.$maxWidth && props.$maxWidth > 0
            ? css`
                  max-width: min(100%, ${props.$maxWidth}px);
              `
            : css`
                  max-width: 100%;
              `};
    position: relative;
    z-index: 0;
    ${(props) =>
        props.$cover &&
        css`
            inset: 0;
            margin: 0;
            overflow: hidden;
            pointer-events: none;
            position: absolute;
        `}

    img {
        display: block;
        height: auto;
        max-width: 100%;
        z-index: 1;
        ${(props) =>
            props.$hasCaption &&
            css`
                background-color: var(--color-white);
                padding: 1.75rem;
            `}
        ${(props) =>
            props.$cover &&
            css`
                height: 100%;
                min-height: 100%;
                object-fit: cover;
                width: 100%;

                ${props.$centerPosition &&
                props.$centerPosition.length == 2 &&
                props.$centerPosition[0] > 0 &&
                props.$centerPosition[1] > 0 &&
                css`
                    object-position: ${props.$centerPosition[0]}%
                        ${props.$centerPosition[1]}%;
                `};
            `}
    }

    ${(props) =>
        props.$overlay &&
        css`
            &::after {
                z-index: 2;
                ${overlay(props.$overlay)};
            }
        `}
`;

export const Darken = styled.div`
    ${darken()}
`;

export const Caption = styled.figcaption`
    ${styleBodyS};
    align-items: flex-end;
    background: var(--color-white);
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-left: 1.75rem;

    span {
        background: var(--color-black-l95);
        color: var(--color-black);
        padding: 0.4375rem 0 0 0.875rem;

        ${MQ.FROM_M} {
            padding: 0.4375rem 0 0 1.3125rem;
        }
    }
`;

export const StyledButton = styled.button`
    background-color: unset;
    border: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 0;

    &:focus-visible {
        outline: 7px solid var(--button-focus);
        outline-offset: 0;
    }
`;

export const inlineImage = css`
    height: auto;
    max-width: 100%;
`;
