// Processing commands for images: https://docs.sixlabors.com/articles/imagesharp.web/processingcommands.html

import React, { ReactElement, useState, useMemo } from "react";
import isEqual from "react-fast-compare";
import uniqid from "uniqid";

import LightBox from "components/LightBox";
import { useBreakpoint } from "hooks";
import { addEditAttributes } from "utils/episerver";

import { Caption, Figure, Darken, StyledButton } from "./Image.styled";
import ImageProps from "./ImageProps";

const Image = ({
    identifier,
    url,
    alt,
    decorative,
    overlay,
    darken,
    caption,
    cover,
    srcSet,
    className,
    editPropertyName,
    maxWidth,
    lazyload = true,
    lightbox = false,
    focalPoint = "0.5|0.5",
    l18n,
    uploadedHeight,
    uploadedWidth,
}: ImageProps): ReactElement => {
    const [lightboxId, _] = useState<string>(uniqid());
    const [lightboxUrl, setLightboxUrl] = useState<string | undefined>(
        undefined,
    );
    const breakpoint = useBreakpoint();
    const isLightboxEnabled = useMemo(
        () => lightbox && breakpoint !== "S",
        [breakpoint, lightbox],
    );

    const srcSetImage = srcSet?.filter((src) => !src.media).pop();
    const srcSetSources = srcSet?.filter((src) => !!src.media);
    const centerPosition = focalPoint
        .split("|")
        .map((num) => Number(num) * 100) as [number, number];

    if (caption) {
        darken = false;
        overlay = undefined;
    }

    const ImageComponent = (
        <Figure
            id={identifier}
            key={identifier || `ImageContent`}
            className={className}
            $overlay={overlay}
            $darken={darken}
            $cover={cover}
            $maxWidth={maxWidth}
            $hasCaption={!!caption}
            $centerPosition={centerPosition}
            {...(editPropertyName ? addEditAttributes(editPropertyName) : {})}
        >
            <picture>
                {srcSetSources?.map((src, index) => (
                    <source
                        type="image/webp"
                        media={src.media}
                        srcSet={src.webpsrcset}
                        key={`sourceWebp${index}`}
                    />
                ))}
                {srcSetSources?.map((src, index) => (
                    <source
                        media={src.media}
                        srcSet={src.srcset}
                        key={`source${index}`}
                    />
                ))}
                {srcSetImage?.webp && (
                    <source
                        key="webP"
                        type="image/webp"
                        srcSet={srcSetImage.webp}
                    />
                )}
                {srcSetImage && (
                    <img
                        key="default"
                        src={srcSetImage.src}
                        alt={decorative ? "" : alt}
                        loading={lazyload ? "lazy" : "eager"}
                    />
                )}
                {!srcSetImage && url && (
                    <img src={url} alt={decorative ? "" : alt} />
                )}
            </picture>
            {darken && <Darken />}
            {caption && (
                <Caption>
                    <span>{caption}</span>
                </Caption>
            )}
        </Figure>
    );

    return isLightboxEnabled ? (
        <>
            <StyledButton
                onClick={() => setLightboxUrl(url)}
                aria-controls={`lightbox-modal-${lightboxId}`}
                aria-label={
                    (l18n?.openImageFullsizeLabel ?? "open image in fullsize")
                    + (caption || alt ? `: ${caption || alt}` : "")
                }
            >
                {ImageComponent}
            </StyledButton>
            <LightBox
                alt={alt}
                identifier={lightboxId}
                imgUrl={lightboxUrl}
                setImgUrl={setLightboxUrl}
                uploadedHeight={uploadedHeight}
                uploadedWidth={uploadedWidth}
                l18n={l18n}
            />
        </>
    ) : (
        ImageComponent
    );
};

export default React.memo(Image, isEqual);
