import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import { LightBoxProps } from "./LightBoxProps";

const LightBox = loadable(
    (): Promise<DefaultComponent<LightBoxProps>> =>
        import(/* webpackChunkName: "LightBox" */ "./LightBox"),
);

const LightBoxLoader = (props: LightBoxProps): ReactElement => (
    <LightBox {...props} />
);

export default LightBoxLoader;
